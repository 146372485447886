import React, { useContext } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { GlobalStateContext } from "../context/GlobalContextProvider"
import { FaWhatsapp, FaRegEnvelope } from "react-icons/fa"

function Contact({ location, data }) {
  const siteTitle = data.site.siteMetadata.title
  const state = useContext(GlobalStateContext)
  const { contact } = state.language

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Contato" />
      <section className="section-contact">
        <div className="wrapper medium fullHeight" data-grid="center column">
          <h1>{contact.title}</h1>
          <section data-grid="spacing center column">
            <div data-cell="shrink" data-grid="small-spacing row">
              <div data-cell="shrink" className="icon">
                <FaWhatsapp className="social-icons" />
              </div>
              <a
                href="https://wa.me/447831725587?text=Hi%20Gabriel!"
                target="_blank"
                rel="noopener noreferrer"
              >
                +44 7831557287
              </a>
            </div>
            <div data-cell="shrink" data-grid="small-spacing row">
              <div data-cell="shrink" className="icon">
                <FaRegEnvelope className="social-icons" />
              </div>
              <a href="mailto:gabrielfonseca.coutinho@outlook.com">
                gabrielfonseca.coutinho@outlook.com
              </a>
            </div>
          </section>
          <form
            data-cell="fill"
            name="contact"
            method="post"
            data-netlify="true"
            action="/success/"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <div data-grid="spacing cols-2">
              <p className="field">
                <input type="text" name="name" id="name" required />
                <label htmlFor="name">{contact.name}</label>
              </p>
              <p className="field">
                <input type="email" name="email" id="email" required />
                <label htmlFor="email">{contact.email}</label>
              </p>
              <p className="field">
                <input type="text" name="subject" id="subject" required />
                <label htmlFor="subject">{contact.subject}</label>
              </p>
              <p className="field">
                <input type="text" name="company" id="company" required />
                <label htmlFor="company">{contact.company}</label>
              </p>
              <p className="field">
                <textarea name="message" id="message" required />
                <label htmlFor="message">{contact.message}</label>
              </p>
            </div>
            <button style={{ float: "right" }} type="submit">
              {contact.send}
            </button>
          </form>
        </div>
      </section>
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
